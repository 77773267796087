<template>
  <van-empty image="search" description="页面好像走丢了">
    <van-button round type="danger" class="bottom-button" @click="onGoBack">
      返回重试
    </van-button>
    <h6 style="text-align:center;margin:7px 0 0 0;color:#999;">{{ r }}</h6>
  </van-empty>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'

export default {
  components: {
  },
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     console.log(from)
  //   })
  // },
  setup (props, context) {
    // const Cookies = inject('Cookies')
    // const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const state = reactive({
      r: ''
    })
    const onGoBack = () => {
      useRouter.go(-1)
    }
    const init = () => {
      state.r = useRouter.currentRoute.value.fullPath
      // console.log(useRouter)
      // const has = useRoute.query.a ?? ''
      // console.log(has)
      // if (has === '') {
      //   console.log('q')
      // } else {
      //   console.log('w')
      // }
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      onGoBack
    }
  }
}
</script>

<style scoped>
.bottom-button {
  width: 160px;
  height: 40px;
}
</style>
